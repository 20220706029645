import React from "react";
import Button from "../components/Button";

function Card({
  file,
  description,
  editionCount,
  name,
  landscape,
  revealed,
  reveal,
  onClick,
}: {
  file: string;
  description: string;
  editionCount: number;
  landscape: boolean;
  name: string;
  revealed?: boolean;
  reveal?: () => void;
  onClick: (data: { file: string; landscape: boolean; title: string }) => void;
}) {
  if (revealed === undefined) revealed = true;

  const w = landscape ? 341 : 228;
  const h = landscape ? 228 : 341;
  const width = w + "px";
  const height = h + "px";

  const style1 = editionCount < 32 ? "dashed" : "solid";
  const style2 = editionCount < 16 ? "dashed" : "solid";
  const style3 = editionCount < 8 ? "dashed" : "solid";

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      style={{
        display: "inline-block",
        width,
        height,
        margin: "30px",
        cursor: revealed ? "pointer" : undefined,
      }}
      onClick={() => revealed && onClick({ file, landscape, title: name })}
    >
      {revealed ? (
        <img
          src={`/art/${file}`}
          style={{
            width,
            height,
            imageRendering: "pixelated",
            position: "absolute",
          }}
          alt={`${name}: ${description}`}
        />
      ) : (
        <div
          style={{
            width,
            height,
            backgroundColor: "#2f2f2f",
            position: "absolute",
          }}
        ></div>
      )}

      <div
        style={{
          border: `2.5px ${style1} #7e8ffa`,
          width: w - 5 + "px",
          height: h - 5 + "px",
          position: "absolute",
        }}
      />
      {editionCount < 128 && (
        <div
          style={{
            border: `2.5px ${style2} #7e8ffa`,
            width: w - 20 + "px",
            height: h - 20 + "px",
            marginLeft: "7.5px",
            marginTop: "7.5px",
            position: "absolute",
          }}
        />
      )}

      {editionCount < 64 && (
        <div
          style={{
            border: `2.5px ${style3} #7e8ffa`,
            width: w - 35 + "px",
            height: h - 35 + "px",
            marginLeft: "15px",
            marginTop: "15px",
            position: "absolute",
          }}
        />
      )}

      {!revealed && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width,
            height,
            zIndex: 1,
          }}
        >
          <Button onClick={reveal}>Reveal</Button>
        </div>
      )}

      <div style={{ position: "absolute", width }}>
        <div
          style={{
            backgroundColor: "#7e8ffa",
            width: "fit-content",
            margin: "auto",
            padding: "0 1rem",
            marginTop: h - 70 + "px",
          }}
        >
          <p>1/{editionCount}</p>
        </div>
      </div>
    </a>
  );
}

export default Card;
