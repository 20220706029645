import React from "react";
import useWindowDimensions from "../util/useWindowDimensions";

import tlFrame from "../assets/tlFrame.svg";
import blFrame from "../assets/blFrame.svg";
import trFrame from "../assets/trFrame.svg";
import brFrame from "../assets/brFrame.svg";

function CardFull({
  file,
  landscape,
  title,
  hide,
}: {
  file: string;
  landscape: boolean;
  title: string;
  hide: () => void;
}) {
  const dim = useWindowDimensions();
  if (!file) return null;

  const ww = dim.width;
  const wh = dim.height;

  let w = landscape ? ww * 0.9 : (wh * 0.85 * 2) / 3;

  if (landscape && (w * 2) / 3 > wh) {
    w = (0.9 * wh * 3) / 2;
  }
  const h = landscape ? (w * 2) / 3 : (w * 3) / 2;

  const width = w + "px";
  const height = h + "px";

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 5,
        backgroundColor: "#2f2f2f90",
        backdropFilter: "blur(6px)",
      }}
      onClick={hide}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <div style={{ marginBottom: "-3rem" }}>
          <img
            src={`/art/${file}`}
            style={{
              width,
              height,
              imageRendering: "pixelated",
            }}
            alt={`the currently zoomed piece`}
          />
          <h1 style={{ marginTop: "-0.4rem" }}>{title}</h1>
        </div>
      </div>
      <img
        style={{
          position: "fixed",
          left: ww / 2 - w / 2 - 10 + "px",
          top: wh / 2 - h / 2 - 10 + "px",
        }}
        src={tlFrame}
        alt=""
      />
      <img
        style={{
          position: "fixed",
          right: ww / 2 - w / 2 - 10 + "px",
          top: wh / 2 - h / 2 - 10 + "px",
        }}
        src={trFrame}
        alt=""
      />
      <img
        style={{
          position: "fixed",
          left: ww / 2 - w / 2 - 10 + "px",
          bottom: wh / 2 - h / 2 - 10 + "px",
        }}
        src={blFrame}
        alt=""
      />
      <img
        style={{
          position: "fixed",
          right: ww / 2 - w / 2 - 10 + "px",
          bottom: wh / 2 - h / 2 - 10 + "px",
        }}
        src={brFrame}
        alt=""
      />
    </div>
  );
}

export default CardFull;
