import React from "react";
import pack from "../assets/preview.png";
import Sticker from "./Sticker";

function Preview() {
  return (
    <div style={{ width: "300px", zIndex: -2 }}>
      <img
        src={pack}
        style={{
          width: "300px",
          minHeight: 0,
          position: "absolute",
        }}
        alt="A preview of the artwork to be minted"
      />
      <Sticker>.1Ξ</Sticker>
    </div>
  );
}

export default Preview;
