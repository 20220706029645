import React, { ReactElement } from "react";
import "@rainbow-me/rainbowkit/styles.css";

import {
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  configureChains,
  createClient,
  WagmiConfig,
  mainnet,
  sepolia,
} from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

const ALCHEMY_ID = "3k1DKp2GTIZaYKd18X127w3hNkF9QJBB";
const PROJECT_ID = "6c247916789dbdd86d5b41620b4a2291";

const { chains, provider } = configureChains(
  [mainnet, sepolia],
  [alchemyProvider({ apiKey: ALCHEMY_ID }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "Mint Diid",
  projectId: PROJECT_ID,
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

function Wrapper(props: { children: ReactElement }) {
  const theme = darkTheme({
    accentColor: "#7e8ffa",
    accentColorForeground: "#2f2f2f",
    borderRadius: "none",
    fontStack: "system",
    overlayBlur: "small",
  });

  theme.fonts.body = '"VT323", monospace';
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={theme} modalSize="compact">
        {props.children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default Wrapper;
