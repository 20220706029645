const metadata = [
  {
    name: "At The Gates",
    essence: "𝕬𝖉 𝕻𝖔𝖗𝖙𝖆𝖘",
    size: "34.2 kb png",
    description: `I have been waiting for you since the sun set, holding a candle in my hand that flickers with every breath of wind. I sit patiently at the gate, hoping to see your silhouette in the distance, longing to hear your footsteps on the gravel path. 

I wonder where you are, what keeps you from coming back to me. I try not to lose hope, but the night is dark and cold, and the candle is burning low. I pray that you are safe, that you will return soon, that you will embrace me and kiss me and tell me you love me. I wait for you, my love, with a candle in my hand.`,
    file: "atthegates.png",
    landscape: false,
    editionCount: 128,
  },
  {
    name: "Forever Legacy",
    essence: "𝕻𝖊𝖗𝖕𝖊𝖙𝖚𝖚𝖒 𝕷𝖊𝖌𝖆𝖙𝖚𝖒",
    size: "35.1kb png",
    description: `I imagine my future child by the horizon, feeling a wave of joy and anticipation. They are the one I have been waiting for, to share my dreams of exploring the universe and improving the world. They have inherited my curiosity and passion, my knowledge and wisdom, my love and respect. They have my eyes, that reflect the stars. They have my name, that represents my story and my vision. They have my heart, that beats with compassion and courage. They have my spirit, that burns with hope.

I smile, feeling a connection that is beyond words. This is the moment of meeting them, of welcoming and embracing them. This is the moment of seeing the wonder of creation, and feeling the love of the Creator.`,
    file: "foreverlegacy.png",
    landscape: false,
    editionCount: 32,
  },
  {
    name: "Forgotten Refuge",
    essence: "𝕮𝖔𝖓𝖋𝖚𝖌𝖎𝖚𝖓𝖙 𝕴𝖓 𝕺𝖇𝖑𝖎𝖛𝖎𝖔𝖓𝖊𝖒",
    size: "41.4kb png",
    description: `We've survived for so long, hoping for a place to heal. A place where I can forget the horrors, the pain, and the loss. A place where I can see the green, touch the soft, and smell the sweet. A place where I can find hope and beauty. I have finally found it, at the end of these beautiful heavily forested lands. A forgotten refuge, a hidden gem, a path less walked by others. Here, I can rebuild my life, my sanctuary, my haven. Here, I can be myself, and nothing else.

I have found a place to heal, but is it enough to make me feel? A place where I can forget, but is it enough to make me regret? A place where I can see hope and beauty, but is it enough to make me happy? Maybe this is what I was looking for, maybe I'm still looking. Maybe this is my home, maybe I'm still roaming. Maybe this is my path, maybe I'm still lost.`,
    file: "forgottenrefuge.png",
    landscape: false,
    editionCount: 32,
  },
  {
    name: "From Dusk 'Til Dawn",
    essence: "𝖁𝖊𝖘𝖕𝖊𝖗𝖊 𝖀𝖘𝖖𝖚𝖊 𝕬𝖉 𝕷𝖚𝖈𝖊𝖒",
    size: "21.2kb png",
    description: `I sit on the edge of the cliff, feeling the cold wind on my face. The town below me is a sea of lights, flickering and fading in the darkness. I wonder what they are doing, those people who live there. Do they have dreams, hopes, fears? Do they feel trapped, like I do? I have been here for too long, waiting for something to change, something to happen. But nothing ever does. Every day is the same, a cycle of boredom and frustration.

I yearn for a better life, a life of adventure and freedom. A life that is not bound by the limits of this place. I look at the horizon, where the sky is slowly turning from pink to purple. A new day is coming, but it will be just like the old one. I sigh and close my eyes, wishing I could fly away from here. From dusk 'til dawn, I wait. But nothing ever comes.`,
    file: "fromdusktildawn.png",
    landscape: false,
    editionCount: 64,
  },
  {
    name: "Gaining Perspective",
    essence: "𝕹𝖔𝖛𝖚𝖘 𝕻𝖗𝖔𝖘𝖕𝖊𝖈𝖙𝖚𝖘",
    size: "32.2kb png",
    description: `Perched at the brink of the world, above a seething inferno that devoured all I ever knew and loved. The flames lick my skin, but I don't flinch. I only feel the frost of the void, the silence of the end. I gaze at the heavens, the orbs, the wonders. They are so splendid, so tranquil, so out of reach. They inspire me to remember why I'm here, why I made this choice.

I'm not here to grieve, to repent, to surrender. I'm here to discover, to evolve, to ascend. I'm here to gain perspective, to behold the grand design, to find my destiny. I'm here to transcend my humanity, my form, my essence. I'm here to merge with the cosmos.`,
    file: "gainingperspective.png",
    landscape: false,
    editionCount: 128,
  },
  {
    name: "In My Reflections",
    essence: "𝕸𝖎𝖍𝖎 𝕴𝖓 𝕸𝖊𝖓𝖙𝖊𝖒 𝖁𝖊𝖓𝖎𝖘𝖙𝖎",
    size: "34.5kb png",
    description: `I sit by the rocks, in peace, feeling its gentle flow around my legs. I watch the ripples on the surface, reflecting the sunlight and the trees. I look at my own face, mirrored by the liquid glass. I think of you, of us, of what we had and lost. I feel a pang of nostalgia, a longing for the past. You were my everything, my reason, my joy. You made me feel alive, loved, whole. But you are gone now, and I am alone.

I wonder if you ever think of me, if you ever miss me, if you ever regret. I wonder if you still love me, or if you ever did. I wonder if you see me in your reflections, or if you have forgotten me.`,
    file: "inmyreflections.png",
    landscape: false,
    editionCount: 16,
  },
  {
    name: "Just Breathe",
    essence: "𝕽𝖊𝖘𝖕𝖎𝖗𝖆𝖗𝖊",
    size: "38.0kb png",
    description: `Standing in quiescence, in this body of water, feeling its warmth and sparkle on my skin. I look up and see the rain, falling from the sky and the leaves. I smell the flowers, blooming and fragrant around me. I breathe deeply, letting go of my worries and pains.

I realize how lucky I am, how beautiful life is, how much I have to be grateful for. But I also feel a twinge of sadness, a hint of regret, a trace of fear. I know that this moment is fleeting, that nothing lasts forever, that everything changes. 
      
I know that I have to face reality, to deal with my problems, to make my choices. I know that I have to live, to love, to grow. I just breathe, and savor this moment.`,
    file: "justbreathe.png",
    landscape: false,
    editionCount: 64,
  },
  {
    name: "Like The Tide",
    essence: "𝕴𝖓𝖋𝖑𝖚𝖚𝖓𝖙 𝕾𝖎𝖈𝖚𝖙 𝕬𝖊𝖘𝖙𝖚𝖘",
    size: "30.6 kb png",
    description: `Dancing at the edge of the world, feeling the tide and the wind caress my feet. I gaze at the horizon, dazzling and infinite in the sun. I grasp the sand, vibrant and smooth in my hands. I flow, like life does, with its highs and lows, its bliss and agony, its trials and triumphs.

I accept it all, knowing that nothing is eternal, that everything is interrelated, that everything is a blessing. I beam, and feel the harmony and love in my soul. I cherish it, that we are intertwined as people, and it is all a miracle. I laugh, and feel the balance. I am like the tide, shifting and transforming, but always returning to the source.`,
    file: "likethetide.png",
    landscape: false,
    editionCount: 16,
  },
  {
    name: "Overstayed My Welcome",
    essence: "𝕬𝖉𝖛𝖊𝖓𝖆 𝕰𝖌𝖔 𝕾𝖚𝖒 𝕳𝖎𝖈",
    size: "34.6kb png",
    description: `I wander through the overgrown downtown, feeling the loneliness and the darkness in my bones. The moon engulfs the ponds of water and the reflections of the buildings, creating a surreal and haunting landscape. I look for a path, a way out, a sign of hope. But I find none, only decay and ruin, only silence and despair.

I am a stranger in a place that I once used to call home, a place that I once used to love, a place that I once used to belong. But I don't anymore, I don't fit in, I don't matter. I have overstayed my welcome, and now I must leave. But where do I go, what do I do, who do I become? I don't know, I don't care, I don't feel. I just walk, and walk, and walk.
      
I walk until I can't walk anymore, until I collapse on the ground, exhausted and defeated. I lie there, on the cold and wet pavement, feeling the pain and the sorrow in my heart. The moon mocks me with its brightness, the stars taunt me with their beauty. The night laughs at me with its cruelty, the world hates me with its indifference. I continue to cry, until I have no tears left. I feel a cold hand touch my shoulder, and I flinch. I look up, and I see a face that I recognize, a face that I love, a face that I miss.
      
She smiles at me, and she hugs me. She tells me that she is sorry, that she is proud of me, that she loves me. She tells me that she is here to take me home, to take me to a better place. She tells me that I have suffered enough, that I have earned my peace, that I have fulfilled my purpose. She tells me to close my eyes, and to trust her. She tells me to let go, and to follow her. She tells me to be happy, and to smile.
      
I do as she says, and I feel a warmth in my chest, a light in my head, a joy in my soul. I feel a lift in my body, a release in my mind, a freedom in my spirit. I feel a love in my heart, a love that fills me, a love that heals me.
      
I open my eyes, and I see a new world, a world of beauty and harmony, a world of grace and glory. I see the sun shining brightly, the sky sparkling with stars, the earth blooming with flowers. I see the angels singing sweetly, the saints smiling kindly, the Lord welcoming warmly.
      
I see her holding my hand, and she leads me to the gates. She tells me that this is my home now, that this is where I belong now, that this is where I will be happy now.
      
She tells me that this is the end of my story.
      
And it is a good one.`,
    file: "overstayedmywelcome.png",
    landscape: false,
    editionCount: 128,
  },
  {
    name: "Outbound",
    essence: "𝕰𝖝𝖙𝖗𝖊𝖒𝖚𝖒 𝕬𝖌𝖒𝖊𝖓",
    size: "35.5kb png",
    description: `Next stop on the 1 train, 59th street, feeling the rust and the dust on my fingers. The windows are cracked and dirty, the seats are torn and stained, the doors are jammed and silent. The train is a relic of a past that is gone, a past that I miss, a past that I love. I remember how we used to hop the train, take it to Manhattan, watch the cruise ships by the pier come and go. The sound of their horns reverberating through the coast, the smell of their engines filling the air, the sight of their passengers waving and smiling. 

We used to dream of joining them, of sailing away to a new world, a better world, a happier world. We used to sit on the couch with grandpa, listening to his stories, laughing at his jokes, learning from his wisdom. He used to tell us that life is an adventure, that we should always be curious, that we should always be hopeful. He used to hug us and kiss us and tell us that he loved us. But he is gone now, and so are they. The ships, the passengers, the couch, the train. All gone, all lost, all outbound.`,
    file: "outbound.png",
    landscape: false,
    editionCount: 128,
  },
  {
    name: "The Prayer",
    essence: "𝕺𝖗𝖆𝖓𝖉𝖎",
    size: "38.5kb png",
    description: `My head's down within my floral embrace, feeling the gentle breeze caressing my face. I close my eyes and whisper a prayer, hoping to connect with the divine. I sense a warmth in my heart, a light in my soul, a peace in my mind. I open my eyes and gasp, beholding the most glorious sight.

The forest around me is transformed into a garden of Eden, filled with vibrant flowers and singing birds. The trees are glowing with a golden hue, the leaves are shimmering with dew. The air is fragrant with a heavenly scent, the sound is harmonious with a celestial chant. In the center of it all, a radiant figure stands, smiling at me with love and grace. It is God, and He has heard my prayer. I am awestruck, in complete awe, at a loss of words. I can only bow my head and say: Thank you.`,
    file: "theprayer.png",
    landscape: false,
    editionCount: 4,
  },
  {
    name: "They Came From God",
    essence: "𝖁𝖊𝖓𝖊𝖗𝖚𝖓𝖙 𝕯𝖊 𝕮𝖆𝖊𝖑𝖔",
    size: "38.8kb png",
    description: `I was walking through the lush greenery of the rainforest, admiring the colorful flowers and the exotic birds, when I saw her. She was standing by the edge of a pond, her white dress flowing in the gentle breeze. She had long pale-colored hair that cascaded over her shoulders, and a pair of wings that shimmered in the sunlight. She looked like an angel, a heavenly being sent to earth.

She turned and saw me, and smiled. Her eyes were light as the sky, and her face was radiant with beauty. She beckoned me to come closer, and I felt a surge of attraction and awe. She was the most incredible woman I had ever seen, almost like I had always known her. I approached her slowly, wondering if she was real or a dream. She reached out her hand and touched my cheek, and I felt a warmth in my heart. I thought to myself, they came from God.`,
    file: "theycamefromgod.png",
    landscape: false,
    editionCount: 16,
  },
  {
    name: "Until You Return",
    essence: "𝕯𝖔𝖓𝖊𝖈 𝕽𝖊𝖉𝖎𝖙",
    size: "28.2kb png",
    description: `I stood calmly under the starry sky, feeling the gentle breeze on my face. The night is quiet, except for the occasional sound of a wind whistling and passing by. I look at the moon, wondering if you are looking at it too, wherever you are. 

We used to walk together in this park, holding hands and talking about our dreams. You always made me laugh with your jokes and stories. You always smiled from my kisses, and from the compliments I gave. You always made me happy with your presence and love.
      
But then you had to go. You had to leave me for a better opportunity, a bigger challenge, a brighter future. You said you would come back soon, that you would keep in touch, that you would never forget me. You said you loved me more than anything, and that nothing would change that.
      
I believed you. I still do. But it's been so long since I heard from you. So long since I saw your face, heard your voice, felt your touch. So long since I felt alive.
      
I miss you so much. I worry about you every day. I wonder if you are safe, if you are happy, if you are still mine. I wonder if you ever think of me, if you ever miss me, if you ever regret leaving me.
      
I try to be strong, to be patient, to be hopeful. But it's hard. It's hard to live without you, to smile without you, to breathe without you.
      
So I come here every night, hoping to see you again, hoping to hear your footsteps behind me, hoping to feel your arms around me. I come here every night, waiting for you to return, waiting for you to say that you are sorry, waiting for you to say that you love me.
      
I come here every night, until the dawn breaks, until the flowers wilt, until the moon fades.
      
I come here every night, until you return.`,
    file: "untilyoureturn.png",
    landscape: false,
    editionCount: 128,
  },
  {
    name: "Wishful Thinking",
    essence: "𝕾𝖕𝖊 𝕮𝖔𝖌𝖎𝖙𝖆𝖙𝖎𝖔𝖓𝖎𝖘",
    size: "24.1kb png",
    description: `The water's filling my lungs, choking me with its cold embrace. I struggle to keep my head above the surface, but the waves are too strong. They push me down, down, down into the dark abyss. I clutch the flower in my hand, the only thing that gives me hope. It glows with a soft light, like a star in the night sky. It is the last gift from her, the one who loved me more than anything. She told me to never let go of it, to never give up on life. She said it would protect me, guide me, and heal me. She said it was our bond, our promise, our destiny.

But she is gone now, and I am alone. Alone in this vast ocean, with no one to help me, no one to save me. I feel the flower slipping from my grasp, its light slowly fading away. I try to hold on to it, to hold on to her memory, to hold on to our dream. But it is too hard, too painful, too much. I can't do this anymore. I can't fight this anymore. I can't live this anymore.
      
I look up at the sky, and see the Sun shining brightly. It is a beautiful day to die, a beautiful day to say goodbye. I close my eyes, and let go of the flower. I let go of everything. I feel a sense of peace, of relief, of freedom. I am ready to join her, to be with her again, to be happy again.
      
Suddenly, a miracle occurs. A wonder beyond my wildest dreams. The flower returns to me, defying the current. It shines like a lighthouse, a signal of hope. It caresses my hand, and I feel a shock of warmth. My heart thumps, my lungs fill, my spirit revives. I open my eyes, and sense the urgency.
      
With a surge of willpower, I summon the last of my strength to survive. The tide turns in my favor, and I swim desperately towards an unknown shore. I collapse on the sand, gasping for air that I thought would be my final breath. I press the flower to my chest, feeling its warmth and light.
      
"I guess it's not over yet," I say.
      
“It's just the beginning, or maybe it was just wishful thinking.”`,
    file: "wishfulthinking.png",
    landscape: false,
    editionCount: 8,
  },
  {
    name: "Within Nature",
    essence: "𝕴𝖓 𝕹𝖆𝖙𝖚𝖗𝖆",
    size: "34.1kb png",
    description: `Quiet on the porch, I watch the hummingbird dart from flower to flower. Its wings beat so fast reverberating, they create a soft hum in the air. It is a marvel of nature, a tiny jewel that sparkles in the sun. It sips a dose of nectar from the blossoms, unaware of my presence. I get a surge of wonder and enlightenment, almost paradoxical, as if I have been granted a glimpse of something sacred.

The hummingbird flies high, leaving me in a sense of awe. I look around and see the beauty within nature everywhere. The flowers are vibrant and fragrant, the grass is green and lush, the trees are tall and majestic. The sky is blue and clear, incredibly vivid, the clouds are white and fluffy, the breeze is so gentle and refreshing.
      
Nature pervades everything, shimmering heavenly, colors so refreshing, a dose of the nectars of life, a splash of radiance. I feel alive and grateful, as if I have been blessed by the divine.`,
    file: "withinnature.png",
    landscape: false,
    editionCount: 128,
  },
  {
    name: "Within Reach",
    essence: "𝕰𝖝𝖙𝖊𝖓𝖉𝖊 𝕴𝖓 𝕮𝖆𝖊𝖑𝖚𝖒",
    size: "24.6kb png",
    resolution: "956x1436",
    description: `I laid asleep on the soft grass, feeling the warmth of the sun on my skin. The field was full of flowers, colorful and fragrant. They swayed gently in the breeze, creating a symphony of scents and sounds. I closed my eyes and breathed deeply, letting go of my worries and fears.

The world had ended, but not for me. Not for us. We were the lucky ones, the survivors. We had found a safe haven, a place where nature had thrived and healed itself. A place where we could live in harmony with the earth, without the pollution, the violence, the greed that had destroyed our civilization.
      
We had hope. Hope for a better future, hope for a new beginning. Hope that we were not alone, that there were others like us out there. Others who had escaped the apocalypse, who had preserved the knowledge and the culture of our ancestors. Others who could help us rebuild and restore what we had lost. That hope was within reach.
      
I opened my eyes and looked up at the sky. It was clear and blue, like a painting. A painting that was interrupted by an unknown pilot. Streaking across the sky, in quiet essence.
      
It was an aircraft. A unique aircraft, unlike any I had ever seen before. It was sleek and shiny, with asymmetrical wings that flew gracefully. It looked like a bird, a metal bird that soared through the air with ease.
      
It was quiet, too. It did not make any noise, except for a faint humming sound that was barely audible. It did not disturb the peace of the field, or the birds that flew around it.
      
It was beautiful. And it was a sign.
      
A sign that we were not alone.
      
A sign that there was someone out there who had advanced technology, who had mastered the art of flying.
      
A sign that there was someone out there who wanted to communicate with us, who wanted to share their knowledge and their culture with us.
      
A sign that there was someone out there who had hope, too.
      
I watched as the aircraft flew by, feeling a surge of excitement and curiosity. I wondered who they were, where they came from, what they wanted. I wondered if they would land, if they would talk to us, if they would be friendly or hostile.
      
I wondered if they would change our lives forever.
      
I reached out my hand, as if I could touch them. As if I could join them in their flight.
      
They were within reach.
      
And so was our hope.`,
    file: "withinreach.png",
    landscape: false,
    editionCount: 4,
  },
];

export default metadata;
