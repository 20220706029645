import React from "react";
import Button from "./Button";

function Counter({
  n,
  setN,
  max,
}: {
  n: number;
  setN: (newN: number) => void;
  max: number;
}) {
  return (
    <div style={{ display: "inline-block" }}>
      <div style={{ display: "inline-block", width: "120px" }}>
        {n > 1 && <Button onClick={() => setN(n - 1)}>-</Button>}
      </div>
      <div style={{ display: "inline-block " }}>
        <input
          style={{
            backgroundColor: "#7e8ffa",
            color: "#FFFFFF",
            border: "none",
            fontSize: "30px",
            textAlign: "center",
            width: "60px",
            height: "52px",
            top: "5px",
            position: "relative",
          }}
          type="number"
          onChange={(e) => {
            if (!e.target.value) return setN(0);
            const nn = parseInt(e.target.value);

            if (isNaN(nn)) return;

            if (nn > max) return setN(max);

            setN(nn);
          }}
          value={n === 0 ? "" : n}
        />
      </div>
      <div style={{ display: "inline-block", width: "120px" }}>
        {n < max && <Button onClick={() => setN(n + 1)}>+</Button>}
      </div>
    </div>
  );
}

export default Counter;
