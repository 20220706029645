import React, { Fragment, useEffect, useState } from "react";
import NavHeader from "../common/NavHeader";
import CornerLogo from "../common/CornerLogo";
import Preview from "../common/Preview";
import Button from "../components/Button";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import abi from "../util/abi";
import { BigNumber } from "ethers";
import Connect from "../common/Connect";
import Pending from "../common/Pending";
import Counter from "../components/Counter";
import useWindowDimensions from "../util/useWindowDimensions";

const SEPOLIA_CONTRACT_ADDRESS = "0x79376Eff846eA6042fCCfCF1D8C52DD089443e39";
const MAINNET_CONTRACT_ADDRESS = "0x877D52d7467C73A6ab8Ca5b7B55F8b779dEcD1aa";

function MintAction() {
  const { width } = useWindowDimensions();

  const isNarrow = width < 1000;

  const [count, setCount] = useState(1);
  const { isConnected, isConnecting, address } = useAccount();

  const network = useNetwork();

  const CONTRACT_ADDRESS =
    network?.chain?.name === "Ethereum"
      ? MAINNET_CONTRACT_ADDRESS
      : SEPOLIA_CONTRACT_ADDRESS;

  const allocationData = useContractRead({
    abi,
    address: CONTRACT_ADDRESS,
    functionName: "minted",
    args: [address],
  });

  let allocation = allocationData.data
    ? (allocationData.data as BigNumber)?.toNumber()
    : 3;

  allocation = 3 - allocation;

  const alStartData = useContractRead({
    abi,
    address: CONTRACT_ADDRESS,
    functionName: "allowlistStartTime",
  });

  const supplyData = useContractRead({
    abi,
    address: CONTRACT_ADDRESS,
    functionName: "remainingSupply",
  });

  const initialAlStartTime = alStartData.data
    ? 15 + (alStartData.data as BigNumber)?.toNumber() - Date.now() / 1000
    : 0;

  const [timeToAlStart, setTimeToAlStart] = useState(initialAlStartTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToAlStart(timeToAlStart - 1);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAlStartTime]);

  const pubStartData = useContractRead({
    abi,
    address: CONTRACT_ADDRESS,
    functionName: "publicStartTime",
  });

  const initialPubStartTime = pubStartData.data
    ? 15 + (pubStartData.data as BigNumber)?.toNumber() - Date.now() / 1000
    : 0;

  const [timeToPubStart, setTimeToPubStart] = useState(initialPubStartTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToPubStart(timeToPubStart - 1);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPubStartTime]);

  const price = BigNumber.from("100000000000000000");
  const remainingMints = (supplyData?.data as BigNumber)?.toNumber() ?? 0;

  const { config } = usePrepareContractWrite({
    address: CONTRACT_ADDRESS,
    abi,
    functionName: "mint",
    overrides: {
      value: price.mul(count),
      gasLimit: BigNumber.from(350000).mul(count),
    },
  });

  const { data, isLoading, isSuccess, isError, error, write } =
    useContractWrite(config);

  const waitData = useWaitForTransaction({
    hash: data?.hash,
  });

  if (isLoading || isSuccess || waitData.isLoading || waitData.isSuccess) {
    return (
      <Pending
        isLoading={!waitData.isSuccess}
        hash={data?.hash}
        approved={isSuccess}
      />
    );
  }

  if (!isConnected) {
    if (isConnecting) {
      return <h1>connecting ...</h1>;
    } else {
      return <Connect />;
    }
  }

  if (timeToAlStart > 0) {
    // seconds to start
    const secs = String(Math.floor(timeToAlStart % 60)).padStart(2, "0");
    // mins = (time / 60) % 60
    const mins = String(Math.floor((timeToAlStart / 60) % 60)).padStart(2, "0");
    // hrs = (time / 60 / 60)
    const hrs = String(Math.floor(timeToAlStart / 60 / 60)).padStart(2, "0");
    return (
      <Fragment>
        <h3 style={{ textAlign: "center" }}>Allowlist mint starts in</h3>
        <h1 style={{ textAlign: "center" }}>
          {hrs}&#58;{mins}&#58;{secs}
        </h1>
      </Fragment>
    );
  }

  if (allocation === 0 && timeToPubStart > 0) {
    // seconds to start
    const secs = String(Math.floor(timeToPubStart % 60)).padStart(2, "0");
    // mins = (time / 60) % 60
    const mins = String(Math.floor((timeToPubStart / 60) % 60)).padStart(
      2,
      "0"
    );
    // hrs = (time / 60 / 60)
    const hrs = String(Math.floor(timeToPubStart / 60 / 60)).padStart(2, "0");
    return (
      <Fragment>
        <h3 style={{ textAlign: "center" }}>Public mint starts in</h3>
        <h1 style={{ textAlign: "center" }}>
          {hrs}&#58;{mins}&#58;{secs}
        </h1>
      </Fragment>
    );
  }

  if (allocation === 0) {
    return <h1>Can't mint yet!</h1>;
  }

  if (remainingMints === 0) {
    return <h1>SOLD OUT!!</h1>;
  }

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>{remainingMints} packs left!</h2>
      {isError && (
        <h1 style={{ color: "#fc3400", textAlign: "center" }}>
          {error?.message}
        </h1>
      )}
      {allocation > 1 && (
        <div
          style={{
            width: "fit-content",
            margin: "auto",
            display: isNarrow ? undefined : "inline-block",
          }}
        >
          <Counter n={count} setN={setCount} max={allocation} />
        </div>
      )}
      <div
        style={{
          display: isNarrow ? undefined : "inline-block",
          width: "fit-content",
          margin: "auto",
        }}
      >
        <Button onClick={() => write && write()}>mint</Button>
      </div>
    </div>
  );
}

function Mint() {
  return (
    <Fragment>
      <NavHeader />
      <div
        style={{
          margin: "auto",
          marginTop: "50px",
          width: "fit-content",
          height: "500px",
          zIndex: -2,
        }}
      >
        <Preview />
      </div>
      <div style={{ width: "100%" }}>
        <div style={{ margin: "auto", width: "fit-content" }}>
          <MintAction />
        </div>
      </div>
      <CornerLogo />
    </Fragment>
  );
}

export default Mint;
