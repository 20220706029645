import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Mint from "./routes/Mint";
import Reveal from "./routes/Reveal";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Mint />,
    errorElement: <div>Not Found?</div>,
  },
  {
    path: "/reveal/:hash",
    element: <Reveal />,
  },
]);

function App() {
  return (
    <div
      style={{
        width: "90vw",
        minHeight: "90vh",
        margin: "5vh 5vw",
        border: "2.5px solid #7e8ffa",
        borderRadius: "15px",
      }}
    >
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
