import React, { Fragment, useState } from "react";
import { Link, useParams } from "react-router-dom";

import tlBorder from "../assets/tlBorder.svg";
import trBorder from "../assets/trBorder.svg";
import blBorder from "../assets/blBorder.svg";
import brBorder from "../assets/brBorder.svg";
import level from "../assets/Level.svg";
import record from "../assets/Record.svg";
import bg from "../assets/background.png";
import { useWaitForTransaction } from "wagmi";
import { ethers } from "ethers";
import abi from "../util/abi";
import metadata from "../util/metadata";
import Card from "../common/Card";
import Button from "../components/Button";
import useWindowDimensions from "../util/useWindowDimensions";
import CardFull from "../common/CardFull";

const cartaeABI = new ethers.utils.Interface(abi);

const PACK_SIZE = 4;

function Reveal() {
  let { hash } = useParams();

  const { width } = useWindowDimensions();

  const isNarrow = width < 1200;

  const { data } = useWaitForTransaction({
    hash: hash as `0x${string}`,
  });

  let pull: any = [];
  if (data) {
    const log = cartaeABI.parseLog(data.logs[0]).args;

    for (let i in log[3]) {
      const count = log[4][i].toNumber();

      for (let j = 0; j < count; j++) {
        pull.push(metadata[log[3][i].toNumber() - 17]);
      }
    }
  }

  const rev = [];
  for (let i = 0; i < pull.length; i++) {
    rev.push(false);
  }

  const [revealed, setRevealed] = useState(rev);

  const [pack, setPack] = useState(0);

  const [show, setShow] = useState({ file: "", landscape: false, title: "" });

  const packCount = Math.ceil(pull.length / PACK_SIZE);

  const packButtons = [];

  for (let i = 0; i < packCount; i++) {
    packButtons.push(i);
  }

  const reveal = (i: number) => () => {
    const r = [...revealed];
    r[i] = true;
    setRevealed(r);
  };

  return (
    <Fragment>
      <CardFull
        file={show.file}
        landscape={show.landscape}
        title={show.title}
        hide={() => setShow({ file: "", landscape: false, title: "" })}
      />
      <div
        style={{
          width: "100vw",
          height: "fit-content",
          minHeight: "100vh",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          imageRendering: "pixelated",
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "fit-content",
            minHeight: "100vh",
            marginTop: isNarrow ? "100px" : undefined,
            marginBottom: isNarrow ? "150px" : undefined,
            flexDirection: isNarrow ? "column" : "row",
          }}
        >
          {pull.map(
            (c: any, i: number) =>
              i >= pack * PACK_SIZE &&
              i < (pack + 1) * PACK_SIZE && (
                <Card
                  onClick={(data) => {
                    setShow({ ...data });
                  }}
                  name={c.name}
                  description={c.description}
                  editionCount={c.editionCount}
                  file={c.file}
                  landscape={c.landscape}
                  revealed={revealed[i] ?? false}
                  reveal={reveal(i)}
                />
              )
          )}
        </div>
        {pull.length / PACK_SIZE > 1 && (
          <div
            style={{
              position: "fixed",
              bottom: "50px",
              left: "50px",
              zIndex: 2,
            }}
          >
            <h1 style={{ marginLeft: "2rem", marginBottom: "-20px" }}>Pack:</h1>
            {packButtons.map((b) => (
              <div style={{ display: "inline-block", marginRight: "-40px" }}>
                <Button onClick={() => setPack(b)}>{(b + 1).toString()}</Button>
              </div>
            ))}
          </div>
        )}
        <div
          style={{
            position: "fixed",
            bottom: "50px",
            right: "50px",
            zIndex: 2,
          }}
        >
          <h1 style={{ marginLeft: "2rem", marginBottom: "-20px" }}>
            Share your pack!
          </h1>
          <div style={{ display: "inline-block" }}>
            <Button
              onClick={() =>
                window.open(
                  `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    `Picked up my Amorphous pack from @0xdiid and @tetragocommando !
                   https://diid.art/reveal/${hash}`
                  )}`,
                  "_blank"
                )
              }
            >
              Tweet It!
            </Button>
          </div>
        </div>
        <img
          style={{ position: "fixed", top: "30px", left: "30px" }}
          src={tlBorder}
          alt=""
        />
        <Link to="/mint">
          <img
            style={{ position: "fixed", top: "50px", left: "50px" }}
            src={record}
            alt=""
          />
        </Link>
        <img
          style={{ position: "fixed", top: "30px", right: "30px" }}
          src={trBorder}
          alt=""
        />
        <img
          style={{ position: "fixed", bottom: "30px", left: "30px" }}
          src={blBorder}
          alt=""
        />
        <img
          style={{ position: "fixed", bottom: "30px", right: "30px" }}
          src={brBorder}
          alt=""
        />
        <img
          style={{
            position: "fixed",
            top: "calc(50vh - 145px)",
            right: "30px",
          }}
          src={level}
          alt=""
        />
      </div>
    </Fragment>
  );
}

export default Reveal;
