import React from "react";
import sticker from "../assets/sticker.svg";

function Sticker({ children }: { children: string }) {
  return (
    <div
      style={{
        position: "absolute",
        rotate: "-10deg",
        marginTop: "-30px",
      }}
    >
      <h1
        style={{
          position: "absolute",
          paddingTop: "18px",
          width: "100%",
          textAlign: "center",
        }}
      >
        {children}
      </h1>
      <img src={sticker} alt={`a price tag saying ${children}`} />
    </div>
  );
}

export default Sticker;
