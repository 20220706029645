import React from "react";
import diid from "../assets/diid.svg";

function CornerLogo() {
  return (
    <img
      style={{
        position: "fixed",
        bottom: "calc(5vh + 30px)",
        left: "calc(95vw - 110px)",
      }}
      src={diid}
      alt="the diid wordmark"
    />
  );
}

export default CornerLogo;
