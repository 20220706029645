import React from "react";
import plus from "../assets/plus.svg";
import square from "../assets/square.svg";
import minus from "../assets/minus.svg";

function NavHeader() {
  return (
    <div
      style={{
        position: "relative",
        borderBottom: "2.5px solid #7e8ffa",
        padding: "5px 25px",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <img style={{ marginRight: "10px" }} src={square} alt="close" />
        <img
          style={{ marginRight: "10px", marginLeft: "10px" }}
          src={minus}
          alt="minus"
        />
        <img style={{ marginLeft: "10px" }} src={plus} alt="maximize" />
        <div style={{ flexGrow: 1 }}></div>
        {/*<Connect />*/}
      </div>
    </div>
  );
}

export default NavHeader;
